import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';

// LOGO IMAGE 
import GuntnerSert from './Serts/guntner_sert.jpg'

const GuntnerPageHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
            <div className="refeng-page-container" style={{display: 'grid', alignContent: 'center'}}>
                <div className="postcontent">
                    <Container fluid>
                        <Row>
                            <Col xl={8}>
                                <h4>Компания Guntner</h4>
                                <br/>
                                <p>
                                    Основанная в далеком 1931 году в Мюнхене для производства холодильного оборудования, 
                                    сегодня компания является мировым экспертом в области коммерческого и промышленного 
                                    холода. И всё это благодаря неустанному движению вперед. 
                                </p>
                                <p>
                                    Güntner принимает участие в жизни миллионов людей – от свежих продуктов питания и комфортной 
                                    температуры в офисных зданиях до центров обработки данных и производства энергии.
                                </p>
                                <p>
                                    <strong>Сайт компании:</strong>
                                    <a href="https://www.guntner.com/" rel="noopener noreferrer nofollow" target = "_blank"  aria-label="Guntner website">
                                    &nbsp;&nbsp;www.guntner.com
                                    </a>
                                </p>
                            </Col>
                            <Col></Col>
                            <Col xl={2}>
                                <Zoom>
                                    <img
                                    alt="Сертификат о партнерстве компании Рефинжиниринг с Guntner"
                                    src={GuntnerSert}
                                    width="100%"
                                    />
                                </Zoom>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default GuntnerPageHtml